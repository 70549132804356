import router from '@/router';

const goBack = () => {
  router.go(-1);
};

const push = (path) => {
  router.push(path);
};

const replaceToIndex = () => {
  router.replace("/index");
};

const pushToIndex = () => {
  router.push("/index");
};
const replaceLogin = () => {
  location.reload();
};
const resetPassword = () => {
  router.replace("/user/reset/password");
};

export default {
  replaceLogin,
  goBack,
  push,
  replaceToIndex,
  pushToIndex,
  resetPassword,
};
