<template>
  <div class="page">
    <div class="header">
      <van-nav-bar left-arrow left-text="返回" title="订单详情"  @click-left="goBack" />
    </div>
    <div class="main">

      <van-cell-group title="订单跟踪">
        <van-steps   direction="vertical">
          <van-step :key="log.orderLogId" v-for="log in orderDetail.orderLogs">
            <h3>{{log.remark}}</h3>
            <p>{{log.createTime}}</p>
          </van-step>
        </van-steps>
      </van-cell-group>

      <van-cell-group title="服务信息">
        <van-cell title="服务单位" :value="orderDetail.serviceStoreName" />
        <van-cell title="单位地址" :value="orderDetail.destinationAddress" />
        <van-cell title="服务内容" :value="orderDetail.orderTypeName" />
        <van-cell title="服务人员" :value="orderDetail.currentServiceWorkerName ? orderDetail.currentServiceWorkerName : '暂未接单'" />
        <van-cell title="联系电话" :value="orderDetail.currentServiceWorkerPhone ? orderDetail.currentServiceWorkerPhone : '无'" />
      </van-cell-group>

      <van-cell-group title="订单信息" >
        <van-cell title="订单编号" :value="orderDetail.orderNo" />
        <van-cell title="下单时间" :value="orderDetail.createTime" />
        <van-cell title="车主姓名" :value="orderDetail.customerName" />
        <van-cell title="联系方式" :value="orderDetail.customerMobile" />
        <van-cell title="车牌号" :value="orderDetail.customerPlateNum" />
        <van-cell title="预约时间" :value="orderDetail.planTime" />
      </van-cell-group>



    </div>
  </div>
</template>

<script>
  import history from '@/utils/history';
  import {orderDetailService} from '@/service/order';
  import {Notify, NavBar,CellGroup,Cell,Step, Steps} from "vant";
  export default {
    name: "OrderDetail",
    components:{
      vanNavBar:NavBar,
      vanCellGroup:CellGroup,
      vanCell:Cell,
      vanStep:Step,
      vanSteps:Steps
    },
    data(){
      return {
        orderNo:null,
        orderDetail:{},
      }
    },
    computed:{
      orderSourceFromName(){
        if(this.orderDetail.orderSourceInfo){
          return this.orderDetail.orderSourceInfo.fromName;
        }
        return "";
      }
    },

    methods:{
      goBack:history.goBack,
      loadDetail(){
        orderDetailService(this.orderNo).then(resp => {
          if(resp.success){
            this.orderDetail = resp.data;
          } else {
            Notify(resp.message)
          }
        });
      }
    },
    created() {
      const {orderNo} = this.$route.params;
      this.orderNo = orderNo;
      this.loadDetail();
    }
  }
</script>

<style type="text/less" lang="less">

</style>
